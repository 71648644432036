import request from '@/utils/request.js';
import axios from 'axios';
export function dataList(params) {
  return request.get('/api/operate/conf/crop/category/page', { params });
}

export function dataDelete(params) {
  return request.post('/api/operate/security/system/role/delete', params);
}
export function dataAdd(params) {
  return request.post('/api/operate/security/system/role/add', params);
}
export function dataUpdate(params) {
  return request.post('/api/operate/security/system/role/update', params);
}

export function categoryList(params) {
  return request.get('/api/operate/conf/crop/category/page', { params });
}
export function data2List(params) {
  return request.get('/api/operate/conf/crop/species/page', { params });
}
export function data2Delete(params) {
  return request.post('/api/operate/conf/crop/species/delete', params);
}
export function data2Add(params) {
  return request.post('/api/operate/conf/crop/species/add', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}
export function data2Update(params) {
  return request.post('/api/operate/conf/crop/species/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}

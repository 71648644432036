import request from '@/utils/request.js';
import axios from 'axios';
export function dataList(params) {
  return request.get('/api/operate/user/message/repair/page', { params });
}
export function landList(params) {
  return request.get('/api/operate/agricultural/soilData/page', { params });
}

export function modleList(url, params) {
  return request.get(url, { params });
}

import request from '@/utils/request.js';

// 查询待分配任务记录
export function allocatedPage(params) {
  return request.get('/api/operate/planting/patrol/damage/allocated/page', { params });
}

// 查询已分配任务记录
export function assignedPage(params) {
  return request.get('/api/operate/planting/patrol/damage/assigned/page', { params });
}

// 设置处理人
export function assignResponsible(params) {
  return request.post('/api/operate/planting/patrol/damage/assign/responsible', params);
}

// 发送消息
export function sendMessage(params) {
  return request.post('/api/operate/planting/patrol/damage/send/message', params);
}

<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
};
</script>
<style>
.el-table tbody td .cell:empty::after {
  content: '-';
}
</style>
<style lang="scss">
body {
  margin: 0;
}

.el-tooltip__popper {
  max-width: 500px;
}
.header-bg {
  background-color: #f8f8f9 !important;
}
.header-cell-bg {
  background-color: #f8f8f9 !important;
  color: #333;
}

.pagenation {
  text-align: center;
  margin-top: 20px;
}
.hideUpload {
  .el-upload.el-upload--picture-card {
    display: none;
  }
}
.cell {
  .el-button {
    margin-right: 12px !important;
  }
}
.el-image__error {
  background: url('@/assets/img_error.jpg') !important;

  background-size: 100% 100% !important;
  color: rgba(0, 0, 0, 0) !important;
}
</style>

import request from '@/utils/request.js';
import axios from 'axios';

// 水井设备列表
export function sdsjList(params) {
  return request.post('/api/iot/sdsj/list', params);
}
// 设备统计接口
export function deviceStatistics(params) {
  return request.post('/api/iot/device/deviceStatistics', params);
}

// 报表统计
export function sdsjStatistics(params) {
  return request.post('/api/iot/sdsj/sdsjStatistics', params);
}

// 累计水电量统计
export function sdsjCostByDay(params) {
  return request.post('/api/iot/sdsj/sdsjCostByDay', params);
}

// 累计水电量统计(按月)
export function sdsjCostByMonth(params) {
  return request.post('/api/iot/sdsj/sdsjCostByMonth', params);
}

// 累计水电量统计
export function sdsjSetting(params) {
  return request.post('/api/iot/sdsj/setting/group/setting', params);
}
// 报表-自定义设置-已设置数据
export function sdsjGetSetting(params) {
  return request.post('api/iot/sdsj/getsetting', params);
}

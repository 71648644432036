import request from '@/utils/request.js';

//获取地块详情
export function farmViewDetail(params) {
  return request.get('/api/operate/planting/land/farm/view', { params });
}

//地块产量更新
export function farmUpdate(params) {
  return request.post('/api/operate/planting/land/farm/update', params);
}

//获取农事记录
export function farmWorkList(params) {
  return request.get('/api/operate/planting/land/farm/page', { params });
}
//获取巡田记录
export function patrolList(params) {
  return request.get('/api/operate/planting/land/farm/page/patrol', { params });
}
//所有字典类型
export function getDicAll() {
  return request.get('/api/common/dict/all', {});
}
//轨迹记录测试接口
export function recordReceiveTest(params) {
  return request.post('/api/operate/trajectory/Record/receive/test', params);
}

import request from '@/utils/request.js';
import axios from 'axios';

// 地块使用指导
export function guiDanceHistory(params) {
  return request.get('/api/operate/planting/use/guidance/history', { params });
}

// 种药肥使用情况
export function productionRecord(params) {
  return request.get('/api/operate/planting/use/guidance/production/record', { params });
}

// 遥感情况
export function rsRecordsHistory(params) {
  return request.get('/api/operate/planting/use/guidance/records/history', { params });
}

// 地块对比
export function landCompareList(params) {
  return request.post('api/operate/govserv/land/compare/list', params);
}

// 日期列表
export function compareRemoteSenseHistory(params) {
  return request.get('/api/operate/govserv/land/compare/remoteSense/history', { params });
}

// 遥感对比
export function remoteSenseCompare(params) {
  return request.post('/api/operate/govserv/land/compare/remoteSense/compare', params);
}

// 根据地块id列表获取种植记录
export function compareLandPlantingHistory(params) {
  return request.post('/api/operate/govserv/land/compare/land/plantingHistory', params);
}

// 获取遥感记录列表
export function compareRemoteSenseRecord(params) {
  return request.get('/api/operate/govserv/land/compare/remoteSense/record', { params });
}

import request from '@/utils/request.js';
import axios from 'axios';
export function roleList(params) {
  return request.get('/api/operate/security/customer/role/page', { params });
}

export function roleDelete(params) {
  return request.post('/api/operate/security/customer/role/delete', params);
}
export function roleAdd(params) {
  return request.post('/api/operate/security/customer/role/add', params);
}
export function roleUpdate(params) {
  return request.post('/api/operate/security/customer/role/update', params);
}
export function treeList(params) {
  return request.get('/api/operate/security/customer/role/functionTree', { params });
}
export function roleDetail(params) {
  return request.get('/api/operate/security/customer/role/view', { params });
}
export function roleAuthorize(params) {
  return request.post('/api/operate/security/customer/role/authorize', params);
}

export function businessList(params) {
  return request.get('/api/operate/customer/customer/page', { params });
}
export function businessDelete(params) {
  return request.post('/api/operate/customer/customer/delete', params);
}

export function businessAdd(params) {
  return request.post('/api/operate/customer/customer/add', params);
}
export function businessUpdate(params) {
  return request.post('/api/operate/customer/customer/update', params);
}

export function userList(params) {
  return request.get('/api/operate/security/customer/user/page', { params });
}

export function userDelete(params) {
  return request.post('/api/operate/security/customer/user/delete', params);
}
export function userAdd(params) {
  return request.post('/api/operate/security/customer/user/add', params);
}
export function userUpdate(params) {
  return request.post('/api/operate/security/customer/user/update', params);
}
export function userAuthorize(params) {
  return request.post('/api/operate/security/customer/user/authorize', params);
}
export function roleAll(params) {
  return request.get('/api/operate/security/customer/role/all', { params });
}
export function organizationList(params) {
  return request.get('/api/operate/customer/organization/list', { params });
}

import request from '@/utils/request.js';
import axios from 'axios';
// --------------------------出入库统计-------------------------------
//最右边导出   导出库存记录

export function exportStockHistory(params) {
  return request.get('/api/operate/govserv/productionreport/fertilizer/record/export', { params, responseType: 'blob' });
}
//最左边导出  导出农资及库存列表
export function exportStockHistory1(params) {
  return request.get('/api/operate/govserv/productionreport/fertilizer/stock/export', { params, responseType: 'blob' });
}
//最左边导出  导出农资及库存列表
export function exportStockHistory2(params) {
  return request.get('/api/operate/govserv/productionreport/fertilizer/department/export', { params, responseType: 'blob' });
}
//
export function recordList(params) {
  return request.get('/api/operate/govserv/productionreport/fertilizer/page/production/record', { params });
}
export function departments(params) {
  return request.get('/api/operate/govserv/productionreport/fertilizer/stock/departments', { params });
}
//增加品类
export function listProductionreport(params) {
  return request.get('/api/operate/govserv/productionreport/fertilizer/department/list', { params });
}
//增加品类
export function listProductionreportList(params) {
  return request.get('/api/operate/govserv/productionreport/fertilizer/page', { params });
}
//关联地块接口
export function startPlantingWithScheme(params) {
  return request.post('/api/operate/planting/use/usePlantingScheme', params, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}

import request from '@/utils/request.js';

// 气象情况列表
export function qxList(params) {
  return request.post('/api/iot/conditions/meteorological/history', params);
}

// 土壤情况列表
export function trList(params) {
  return request.post('/api/iot/conditions/soil/history', params);
}

// 虫情列表
export function cqList(params) {
  return request.post('/api/iot/conditions/pest/history', params);
}

// 苗情列表
export function mqList(params) {
  return request.get('/api/iot/conditions/growth/page', { params });
}

// 苗情列表
export function remoteSenseDetail(params) {
  return request.get('/api/iot/conditions/growth/land/remoteSense', { params });
}

import request from '@/utils/request.js';
import axios from 'axios';
export function warmList(params) {
  return request.get('/api/operate/planting/patrol/warm/page', { params });
}
export function dataList(params) {
  return request.get('/api/operate/common/pest/page', { params });
}
export function dataDelete(params) {
  return request.post('/api/operate/common/pest/delete', params);
}
export function answer(params) {
  return request.post('/api/operate/planting/patrol/warm/diagnosis', params);
}

export function messageList1(params) {
  return request.get('/api/operate/user/message/diseases/page', { params });
}

export function diagnoseList(params) {
  return request.get('/api/operate/user/message/diseases/diagnose/page', { params });
}

export function create(params) {
  return request.post('/api/operate/common/pest/create', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
export function update(params) {
  return request.post('/api/operate/common/pest/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  });
}
